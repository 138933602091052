function slideFooter() {
  $('.footer-box').find('.footer-header').click(function(){
    if(!$(this).hasClass('active') && window.innerWidth < 992){
      $('.footer-box').find('.footer-header').removeClass('active');
      $('.slideDown').slideUp();
      $(this).addClass('active');
      $(this).parent().find('.slideDown').slideDown();
    }
  });
}

function slickMain(){
  $('.mainSlider').slick({
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  });
}

function heightListener(element){
  var parents = $('.'+element).closest(".equalizer");
  var maxheight = 0;
  $(document).ready(function(){
    parents.each(function(){
      var elements = $(this).find('.'+element);
      elements.each(function(){
        if ($(this).height() > maxheight){
          maxheight = $(this).height();
        }
      })
      elements.height(maxheight);
      maxheight = 0;
    })
  })
}

function slickProductsMain(){
  $('.productsSliderMain').slick({
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    responsive: [
    {
      breakpoint: 1092,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
  });
}

function slickSliderProducts(){
  $('.sliderProducts').slick({
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    arrows: false,
  });
}

function showHideSideBar() {
  $('.sideBar-header h2').click(function(){
    if($('.sideBar-header h2.active').length == 0){
      showSideBar();
    }
    else {
      hideSideBar();
    }
  });
}

function showSideBar(){
    $('.sideBar-header h2').addClass('active');
    $('.sideBar-list').slideDown();
}

function hideSideBar(){
    $('.sideBar-header h2').removeClass('active');
    $('.sideBar-list').slideUp();
}

function stickMenu() {
  $(document).scroll(function () {
    var height = $(document).scrollTop();
    var header = $(".header");
    var content = $(".wrapper");

    if (height >= 50) {
        header.addClass('header-sticky');
        content.addClass('wrapper-sticky');
    } else {
        header.removeClass('header-sticky');
        content.removeClass('wrapper-sticky');
    }
  });
}

function showHideMenu(){
  $('.header-hamburger').click(function(){
    if($('.menuActive').length == 0){
      showMenu();
    }
    else {
      hideMenu();
    }
  })
}

function showMenu(){
  $('.menu').addClass('menuActive');
  $('.header-lines').addClass('active');
  $('.header-line').addClass('active');
  $('.blackout').addClass('active');
  $('body').addClass('helpers-overflow--hidden');
}

function hideMenu(){
  $('.menu').removeClass('menuActive');
  $('.header-lines').removeClass('active');
  $('.header-line').removeClass('active');
  $('.blackout').removeClass('active');
  $('body').removeClass('helpers-overflow--hidden');
}

function hideMenuByClickingOutside(){
  $('.blackout').click(function(){
    hideMenu();
    $('.level0').removeClass('activeSubmenu');
    hideMobileSubmenu();
  });
}

function showHideMobileSubmenu(){
  $('.level0 > a').on('click', function(e){
    if($(this).siblings(".menu-submenu").length != 0 && $(window).width() < 992){
      e.preventDefault();
      if($('.activeSubmenu').length == 0){
        showMobileSubmenu($(this).parent());
      }
      else {
        hideMobileSubmenu($(this).parent());
      }
    }
  });
}

function showMobileSubmenu(menu){
  menu.addClass('activeSubmenu');
  $('.activeSubmenu .menu-submenu').slideDown();
  $('.menu-content > li').not('.activeSubmenu').css('display', 'none');
  $('.menu-link').css('display', 'none');
  if($(window).width() < 768){
    $('.menu-extraButton').css('display', 'none');
  }
  return false;
}

function hideMobileSubmenu(menu){
  menu.find(".menu-submenu").removeAttr("style");
  menu.removeClass('activeSubmenu');
  $('.menu-content > li').not('.activeSubmenu').removeAttr("style");
  $('.menu-extraButton').removeAttr("style");
  $('.menu-link').removeAttr("style");
  return false;
}

function slickIndustryCarousel(){
  $('.industryCarousel').slick({
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    responsive: [
    {
      breakpoint: 1092,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }

  ]
  });
}

function industryCarouselPopup(){
  $(document).ready(function() {
    $('.industryPopup').magnificPopup({
      type:'image',
      gallery: {
        enabled: true
      },
      titleSrc: 'title',
    });
    $('.industryPopup1').magnificPopup({
      type:'image',
      gallery: {
        enabled: true
      },
      titleSrc: 'title',
    });

  });
}

function validateForm() {
  $('.required').focusout(function(){
    if($(this).val().trim() == "" || $(this).val().trim() == null){
      $(this).addClass("error");
      $(this).next().css('opacity','1');
    }
    else{
      $(this).removeClass("error");
      $(this).next().css('opacity','0');
    }
  });
  $('.emailAddress').focusout(function(){
    var regExp = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if( !( regExp.test($(this).val()) ) ){
      $(this).addClass("error");
      $(this).next().css('opacity','1');
    }
    else{
      $(this).removeClass("error");
      $(this).next().css('opacity','0');
    }
  });
}

function initMap() {
  var map;
  map = new google.maps.Map(document.getElementById('map'), {
  center: {lat: 43.6256405, lng: -79.6693082},
  zoom: 12,
  scrollwheel: false
  });

  var image = '/assets/img/marker.png';
  var beachMarker = new google.maps.Marker({
    position: {lat: 43.6256405, lng: -79.6693082},
    map: map,
    icon: image
  });
}

function slickProductDetails(){
  $('.productDetails-containerSlider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: false,
    asNavFor: '.productDetails-sliderNav',
    draggable: true,
  });
  $('.productDetails-sliderNav').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: '.productDetails-containerSlider',
    focusOnSelect: true,
    arrows: false,
  });
}

function changeTab(){
  $('.tabs-tab').click(function(){
    if(window.innerWidth > 767) {
      var id = $(this).find('a').attr('href');
      $('.tabs-tab').removeClass('active');
      $(this).addClass('active');
      $('.tabContent > div').css("display", "none");
      $(id).fadeIn();
      return false;
    }
    else {
      var width = $('.tabs-tab.active').find('a').width();
      var links = $('.tabs-tab').not('.active').find('a').clone();
      $('.tabs-submenuContainer').css('width', width);
      $('.tabs-submenuContainer').html(links);
      $('.tabs-submenuContainer').addClass("open");
      changeMobileTab();
      hideTabPanel();
      return false;
    }
  });
}


function changeMobileTab(){
  $('.tabs-submenuContainer a').click(function(){
    var id = $(this).attr('href');
    $('.tabs-tab').removeClass('active');
    $('.tabs-tab a[href="'+id+'"]').parent().addClass('active');
    $('.tabs-submenuContainer').html('');
    $('.tabContent > div').css("display", "none");
    $('.tabs-submenuContainer').removeClass("open");
    $(id).fadeIn();
    return false;
  });
}

function hideTabPanel() {
  $('.tabs-tab.active').click(function(){
    if(window.innerWidth < 767 && $('.tabs-submenuContainer.open').length > 0) {
      $('.tabs-submenuContainer').removeClass("open");
      $('.tabs-submenuContainer').html('');
    }
  });
}

function slickProductsSearch(){
  if ($(".productsSlider-counter").length > 5)
  {
    $('.productsSliderSearch').addClass("yellowNavigation");
    heightListener('productsSlider-product');
    $('.productsSliderSearch').slick({
      infinite: true,
      speed: 300,
      slidesToShow: 5,
      responsive: [
      {
        breakpoint: 1092,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
    });
  }
  else {
    $(".productsSlider-counter").addClass("productsSlider-noSlide");
    heightListener('productsSlider-noSlide');
  }
}

function uploadFile() {
  $('.uploadBtn input').change(function(){
     $('.fileName').val($(this).val().replace(/^.*\\/, ""));
  });
}

function enlargeImage(){
  $('.popupImage').magnificPopup({
    type: 'image'
  });
}

function addChromeOperaStyles() {
  var isChrome = !!window.chrome;
  if(isChrome){
    $('.menu-content > li > a').addClass("chrome");
  }
  if (navigator.userAgent.search("Safari") >= 0 && navigator.userAgent.search("Chrome") < 0) {
    $('.menu-content > li > a').addClass("safari");
  }
  if(!(window.mozInnerScreenX == null)) {
    if(navigator.platform.indexOf('Mac')>=0)
    {
      $('.menu-content > li > a').addClass("firefoxMac");
    }
  }
}

function fillQuantity() {
  $('#quantity').change(function(){
    var quantity = $(this).val();
    $('#quantityField input').val(quantity);
  })
}

function fillResources() {
  $('.searchBox input[type="submit"]').click(function(){
    var resources = "";
    $('input:checked').each(function(){
    	resources += $(this).attr("id")+";";
    });
    $('#txtCategories').val(resources.substr(0,resources.length-1));
  });
}

function hideNoResults() {
  $(".hideParent").closest('.searchResultsContainer').css("display", "none");
}

function redirectAfterSubscribe() {
  $( document ).ready(function() {
   if($(".newsletterBox .newsletterBox-info .InfoMessage").text() == "You've been subscribed.") {
     window.location.replace("/Thank-you");
    }
 });
}

function ignoreAutocomplete() {
  $(".newsletterBox .NewsletterSubscription").find("input[type='text']").attr("autocomplete", "off");
}

function hideTabIfEmptyTitle() {
  if($('.tabContent').length) {
    var tabNames = $(".tabContent div h3");
    for(var i = 0; i < tabNames.length; i++){
      if(tabNames[i].innerHTML == ""){
        var emptyTab = tabNames[i].parentNode.parentNode.id;
        $('a[href="#'+emptyTab+'"]').parent().remove();
        $('#'+emptyTab).remove();
      }
    }
    document.getElementsByClassName('tabContent')[0].getElementsByTagName('div')[0].style.display = "block";
    document.getElementsByClassName('tabs-tab')[0].className += " active";
  }
}

function enchanceMultiLevelDropDown() {
  $('select.multiLevelSelector option').each(function() {
    var option = $(this).text();
    $(this).text(option.replace(/\-/g, "\u00A0\u00A0\u00A0\u00A0"))
  })
}

function resourceSearchResultsFiltering() {
  var resultsTable = $(".resultsTable");
  var resultsTableRows = resultsTable.find(".resultsTable-row");

  if (resultsTableRows.find('.resultsTable-download a').length == 0) {
    resultsTable.parent().prepend('<div class="text"><p>Your search has no results. Please try again.</p></div>');
    resultsTable.remove();
  }
  else {
    var resultsToShow = 0;

    resultsTableRows.each(function(){
      var that = $(this);

      if(that.find('.resultsTable-download a').length == 0) {
        that.remove();
      }
      else {
        resultsToShow++;
      }
    });

    console.log(resultsToShow);
  }

  var resultsGroupSize = 10;
  var resultsToLoad = 10;

  $('.resultsTable-row').hide();
  $('.resultsTable-row:lt('+resultsGroupSize+')').show();
  if (resultsToShow > resultsGroupSize) {
    resultsTable.append('<a href="#" id="loadMoreButton" class="button-orange button-orange--noArrow button-orange--narrow button-orange--loadMore">LOAD MORE</a>');
  }

  $('#loadMoreButton').click(function () {
    resultsGroupSize = (resultsGroupSize+resultsToLoad <= resultsToShow) ? resultsGroupSize+resultsToLoad : resultsToShow;
    $('.resultsTable-row:lt('+resultsGroupSize+')').show();
    if (resultsGroupSize >= resultsToShow) {
      $('#loadMoreButton').hide();
    }

    return false;
  });
}

$( document ).ready(function() {
  slideFooter();
  slickMain();
  heightListener('boxes-text');
  heightListener('industries-industry p');
  heightListener('industries-industry h3');
  slickProductsMain();
  slickSliderProducts();
  showHideSideBar();
  stickMenu();
  showHideMenu();
  hideMenuByClickingOutside();
  showHideMobileSubmenu();
  heightListener('industriesList-description p');
  heightListener('industriesList-description h3');
  heightListener('industriesList-description h2');
  slickIndustryCarousel();
  industryCarouselPopup();
  validateForm();
  slickProductDetails();
  heightListener('productDetails-slideMain');
  heightListener('productDetails-slideNav');
  changeTab();
  slickProductsSearch();
  uploadFile();
  enlargeImage();
  addChromeOperaStyles();
  fillQuantity();
  fillResources();
  hideNoResults();
  //redirectAfterSubscribe();
  ignoreAutocomplete();
  hideTabIfEmptyTitle();
  heightListener('listOfProducts-name');
  enchanceMultiLevelDropDown();
  resourceSearchResultsFiltering();
});
